.button-finalizar {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}