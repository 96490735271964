
.btnDisableFaceprint{
    background-image: url("../../../../../../assets/images/facedisable.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 9em;
    height: 11.3em;
}

.btnEnableFaceprint{
    background-image: url('../../../../../../assets/images/rostro.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 10em;
    height: 11.3em;
}

.btnfacialdisable .btnselectautentica {
  color: #ccc;
}

.btnfacialdisable {
    border: 1px solid #666 !important;
}