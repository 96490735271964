body {
  font-family: 'Roboto', sans-serif;
}

* {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

img {
  -webkit-user-drag: none;
  pointer-events: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
}

p,
a,
li,
ul {
  font-family: 'Roboto', sans-serif;
}

button {
  font-weight: bold !important;
}

.btn-primary {
  background-color: #0092bb !important;
  border: #0092bb 2px solid !important;
  padding: 0.2em .8em !important;
  font-size: 2em !important;
  transform: translateX(-50%);
  border-radius: 10px !important;

}

.btn-primary:hover {
  background-color: #eee823 !important;
  border: 2px solid #eee823 !important;
  color: #0092bb !important;
}

.inputDNI{
  border-radius: 7px;
  border: 3px solid #0092bb;
  background-color: #e6f4f8;
  font-size: 2em;
  text-align: center;
  font-weight: bold;
  color: #6c7276;
  margin-bottom: 2%;
}

.inputDNI:focus{
  outline: none;
}

.keyboard-container {
  width: 40%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.myTheme1 .hg-button {
  width: 30% !important;
  height: auto;
  background-color: #f69200;
  color: white;
  border-radius: 5px;
  margin: 5px;
  border: none;
  font-size: 40px;
}

.myTheme1 .hg-button:hover {
  background-color: #3c7a94 !important;
}

.myTheme1 .hg-enter {
  background-color: #fcc505;
  background-image: url('../src/assets/images/tecladoconfirmar.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  font-size: 0;
  margin: 5px;
}

.myTheme1 .hg-button-bksp {
  background-color: #fcc505;
  background-image: url('../src/assets/images/tecladoborrar.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  font-size: 0;
  margin: 5px;
}

.hg-theme-default {
  background-image: url('../src/assets/images/rnpback2error.png');
  background-size: 450PX;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #0092bb;
}

.FaceContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 230px;
}

.contenedorbtnautentica {
  border-radius: 10px;
  border: 1px solid #3789C8;
  width: 80%;
  padding: 1em;
  background: rgba(29, 53, 89, 0.1);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btnselectautentica {
  font-weight: bold;
  color: #3789C8;
}

@keyframes fadeInUp {
    from {
        transform: translate3d(0,100%,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,100%,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.animated {
    animation-duration: 5s;
    animation-fill-mode: both;
    -webkit-animation-duration: 5s;
    -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}

.contenedorirmenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 5em 0 0 0;
}

.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 100%;
  height: 80vh;
  max-height: 100vh;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  z-index: 1050;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-content {
  border-radius: 20px 20px 0 0 !important;
  height: 600px !important;
}

.custom-modal-header.modal-header {
  border-radius: 20px 20px 0 0;
}

.custom-modal-header {
  background-color: #0191bc;
  color: white;
  border-radius: 20px 20px 0 0;
  padding: 15px;
  text-align: center;
}

.custom-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.capture-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.capture-section {
  flex: 0 0 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.control-section {
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.upper-half {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.control-button, .close-button-finger {
  width: 150px;
  height: 150px;
  background-color: #ffd344;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
}

.control-button {
  background-color: #DE5E16;
}

.control-button img {
  max-width: 70%;
  max-height: 70%;
  object-fit: contain;
}

.close-button-finger {
  font-size: 1.5rem;
}

.custom-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 50, 50, 0.8);
  z-index: 1040;
  display: none;
}

.custom-modal-backdrop.show {
  display: block;
}

.waiting-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 50, 50, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.waiting-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  width: 50%;
}

.loading-text {
  margin-top: -10px;
  font-size: 1.5em;
}

.contenedorkiosko{
  overflow: hidden;
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-backdrop {
  background-color: #323232 !important;
}

.modal-backdrop.show {
  opacity: 0.8 !important;
}

.center-screen {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.messageSelect{
  text-align: center;
  color: #837e7e;
  margin-bottom: 20px;
  margin-top: 20px;
}

.CertificateContainer {
  height: 260px;
  width: 200px;
  transition: all 0.8s;
  border: 2px solid #989da0;
  background: #e8eaee;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 0;
}

.CertificateContainer:hover {
  background: #d5d6da;
  border: 3px solid #3789C8;
}

.certbtnimage {
  width: 85%;
}

.textbtnselector {
  text-align: center;
  color: #989da0;
  font-size: 1.1em;
  line-height: 1.1em;
  display: block;
}

.textbtnselector span {
  color: #F69220;
  font-weight: bold;
  font-size: 1.3em;
  clear: both;
  display: block;
}

.contenedortitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.contbtncerthor {
  background: #e8eaee;
  border: 3px solid #e8eaee;
  border-radius: 12px;
  transition: all 0.9s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 320px;
}

.contbtncerthor:hover {
  background: #E5E5E5;
  border: 3px solid #0295d5;
  text-decoration: none; 

}

.contbtncerthor img {
  width: 30%;
  margin-right: 10px;
  margin-left: 12%;
  margin-top: 4%;
  margin-right: 0;
}

.textbtncert {
  color: #0295d5;
  font-size: 1.8em;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.contenedorirmenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 2em 1.5em;
}

.alignbtnfooter {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1em 0 0 0;
  clear: both;
}

.center {
  display: flex;
  align-items: center;
}

.contenedorscroll {
  overflow-y: scroll;
  border-radius: 20px;
  border: 3px solid #e8eaee;
  background-color: #f3f5f8;
  height: 350px;
  width: 450px;
  overflow-x: hidden;
}

.contenedorscroll::-webkit-scrollbar {
  width: 30px;
}

.contenedorscroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 30px;
}

.contenedorscroll::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 30px;
  border: 2px solid #e8eaee;
}

.contenedorscroll::-webkit-scrollbar-thumb:hover {
  background: #3789C8;
}

.ulcontent {
  display: block;
  margin: 0;
  padding: 0;
}

.ulcontent li {
  display: block;
  padding: 1em 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  border-bottom: 1px dotted #ccc;
}

.contentparent {
  margin: .5em 1.5em -10px 1.5em;
  width: 50%;
}

.contentparent h2 {
  font-weight: 600;
  font-size: 1em;
}

.contentparent p {
  font-size: 1.3em;
  color: #3789C8;
  font-weight: bold;
  margin-top: -10px;
}

.ulcontent li a {
  background: #E5E5E5;
  border: 3px solid #E5E5E5;
  color: #3789C8 !important;
  font-size: 1em;
  font-weight: 500;
  padding: .5em;
  margin: 0 1em 0 0;
  width: 30%;
  border-radius: 10px;
  transition: all 0.9s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ulcontent li a:hover {
  background: #e0e0e0;
  border: 2px solid #3789C8;
}

.icondescarga {
  width: 50px;
  margin: 0 0 0 .5em;
}

.contenedorcertificado3 {  
  background: #0095d5;
  color: white !important;
  border: 2px solid #0095d5;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 130px;
  transition: all 0.9s;
}

.contenedorcertificado3 p {
  margin: 5px -5px 5px -20px;
  font-weight: 500;
}

.contenedorcertificado3:hover {  
  background-color: #0579aa;
  border: 2px solid #054f6e;
}

.hover-border {
  background-color: #fdc000;
  border: 2px solid #fdc000;
}

.hover-border:hover {  
  background-color: #c19505;
  border: 2px solid #755b03;
}
  
.span-contacto{
  text-align: center;
  color: #837e7e;
}
  
.phone-input {
  background-color: #e8eaee;
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: .2em;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 2px solid #e8eaee !important;
  text-align: center;
  color: #f16709 !important;
}

.phone-input::placeholder {
  font-size: 0.8em;
  color: #888;
  font-weight: normal;
}

.error-overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 50, 50, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9995;
}

.error-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  width: 80%;
  max-width: 800px;
}

.error-content i {
  font-size: 5em;
  color: #EFC42E;
  margin: 0 0 0.5em 0;
}

.error-content img {
  width: 25%;
}

.error-message {
  font-size: 1.7em;
  margin-bottom: 1em;
  line-height: 1.8em;
}

.error-button {
  width: 200px;
  font-size: 1.5em;
  padding: 0.2em 1.5em;
  background-color: transparent;
  border: 2px solid white;
  color: #eee823;
  border-radius: 7px;
  cursor: pointer;
}

.error-button:hover {
  background-color: #eee823;
  border: 2px solid black;
  color: black;
}

.custom-modal1 {
  z-index: 9997 !important;
}

.custom-modal1 .modal-content {
  width: 90%;
  text-align: center !important;
  height: fit-content !important;
  border-radius: 20px !important;
}

.custom-modal1-backdrop {
  z-index: 9996 !important;
}

.custom-modal1-header {
  background-color: #f16709;
  color: white;
  border-radius: 20px 20px 0 0;
  padding: 7px !important;
  text-align: center;
}

.custom-modal1-header.modal-header {
  border-radius: 20px 20px 0 0;
  justify-content: center;
}