.factrnplogotipo {
    width: 200px;
}

.invoiceRnp_container {
    align-items: center;
    justify-content: center;
}

.scaled-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.scaled-container {
    height: 700px;
    transform: scale(1);
    transform-origin: top left;
    transition: transform 0.3s ease-in-out;
}

@media (max-width: 800px) {
    .scaled-container {
        transform: scale(0.8);
    }
}

@media (max-width: 700px) {
    .scaled-container {
        transform: scale(0.6);
    }
}

@media (max-width: 500px) {
    .scaled-container {
        transform: scale(0.5);
    }
}