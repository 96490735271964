.error-overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/images/rnpback2error.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  background-color: rgba(0, 146, 187, 0.93);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}