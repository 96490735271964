.button-other-certificate {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #008000;
  color: #fff;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}