.pasosclass {
  font-size: 1.4em;
  margin: 0.8em 0;
  color: #3F3F3F;
}

.pasosclass strong {
  color: #eee823;
}

.labelTitles {
  font-size: 1.4em;
}

.IdentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 3;
}

.mensajeimportante {
  padding: 1em;
  border-radius: 10px;
  background: #f06a03;
  color: white;
  width: 70%;
  margin: 1em auto 0;
  font-size: 1em;
}