

.activekiosko{
  background: url("../../assets/images/rnpback1.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  margin:0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: animate 12s ease-in-out infinite;        
}

@media only screen and (max-width: 600px) {
  .activekiosko{
    background: url("../../assets/images/rnpback1mobil.png") no-repeat center center;   
    background-size: cover !important;
  }
}

.modal-backdrop{background: rgba(0, 0, 0, 0.5) !important;}
.modal-backdrop.show{opacity: 0.2 !important;}

@keyframes animate{
  0%{
    background-size:110%;
  }
  50%{
    background-size: 200%;
  }
  100%{
    background-size: 110%;
  }
}

.backmodalactive {
  background: #202B70;
}

.backmodalactive .modal-dialog .modal-content {
  background: none;
  border: 1px solid #263277;
  padding: .1em;
  overflow: hidden;
  height: 700px !important;
}

.backmodalactive .modal-dialog .modal-content .modal-header {
  border-bottom: none;
  padding: 5px;
}

.backmodalactive .modal-dialog .modal-content .modal-body {
  color: #F59221;
  padding: 5px;
}

.backmodalactive .modal-dialog .modal-content .modal-body form {
  color: #fff;
}

.backmodalactive .modal-dialog .modal-content .modal-body form input {
  color: #fff;
  background-color: initial;
}

.backmodalactive .modal-dialog .modal-content .modal-body form select {
  color: #fff;
  background-color: initial;
}

.backmodalactive .modal-dialog .modal-content .modal-body form select option {
  color: #666;
}

.backmodalactive .modal-dialog .modal-content .modal-body form button {
  background: initial;
  color: #F59221;
  border: 1px solid #F59221;
  border-radius: 10px;
  padding: 0.5em 1em;
}

.backmodalactive .modal-dialog .modal-content .modal-body form button:hover {
  background: #F59221;
  color: #fff;
}

.logotipocapture {
  width: 100px;
  display: block;
  float: left;
}

.descmodalcapt {
  float: left;
  display: block;
  margin: 0 0 0 1em;
  color: #6fa5c6;
  font-size: 2rem;
  font-weight: bolder;
}