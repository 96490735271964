.alerta {
    background-color: rgba(0, 0, 0, 0.7);
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: animateautenticacion 12s ease-in-out infinite;
  }
  
  .alerta .contenedorcierre {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .alerta .contenedorcierre .alertcontent {
    width: 75%;
    background: #103056;
    padding: 2em;
    border-radius: 2em;
    margin: 3em 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .alertlogotipo {
    width: 250px;
  }
  
  .iconoalertrojo {
    width: 150px;
  }
  
  .alerta .contenedorcierre .alertcontent h3 {
    color: #fff;
    font-weight: bold;
    text-align: center;
    line-height: 1em;
  }
  
  .alerta .contenedorcierre .alertcontent p {
    color: #ccc;
    font-weight: 100;
    text-align: center;
    font-size: 2em;
    line-height: 1em;
  }