.header-container {
  display: flex;
  align-items: center;
  justify-content: start;
  text-align: left;
}

.header-logo {
  width: 20%;
  margin-right: 10px;
}

.header-text {
  display: inline-block;
  font-size: 20px;
  line-height: 1.2;
  white-space: normal;
}

.highlighted-text {
  color: yellow;
}
  
.contenedorcaptura {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.videocapturamirror {
  width: 100%;
  transform: scaleX(-1);
}

.backcaptureface {
  background: url('../../../../../../../../assets/images/rnpespaciofotografia.png') no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 100%;
  z-index: 0;
}