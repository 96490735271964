.welcome {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	overflow: hidden;
}

.welcome > div {
	text-align: center;
}