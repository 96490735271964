.contenedorcertificado4 {
  background: #e8eaee;
  color: #0295d5 !important;
  border: 3px solid #e8eaee;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  transition: all 0.9s;
}

.contenedorcertificado4 p  {
  margin: 7px -7px;
  font-weight: 500;
}

.contenedorcertificado4:hover {
  background: #e0e0e0;
  border: 3px solid #3789C8;
}