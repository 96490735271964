.cargadoranimado{
  width: fit-content;
}

div.animationcircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin: 0.5rem;
  animation: loading 2.5s ease-in-out infinite;
}

@keyframes loading {
  0%,
  100% {
    transform: scale(0.2);
    background-color: #EEC32B;
  }
  42% {
    background-color: #F05A27;
  }
  50% {
    transform: scale(1);
    background-color: #F49021;
  }
}

div.animationcircle:nth-child(0) {
  animation-delay: 0s;
}
div.animationcircle:nth-child(1) {
  animation-delay: 0.2s;
}
div.animationcircle:nth-child(2) {
  animation-delay: 0.4s;
}
div.animationcircle:nth-child(3) {
  animation-delay: 0.6s;
}
div.animationcircle:nth-child(4) {
  animation-delay: 0.8s;
}
div.animationcircle:nth-child(5) {
  animation-delay: 1s;
}