.ayuda-button {
  width: 200px;
  font-size: 1.5em;
  padding: 0.2em 1.5em;
  background-color:  rgba(50, 50, 50, 0.9);;
  border: 2px solid #f19225;
  color: #f19225;
  border-radius: 7px;
  cursor: pointer;
  margin-left: 30px;
}

.ayuda-button:hover {
  background-color: #f19225;
  border: 2px solid black;
  color: black;
}

.btn-success {
  background-color: #fcc505 !important;
  font-size: 1.5em !important;
  font-weight: 500 !important;
  margin-top: .5em;
  width: 100%;
  height: 50px;
  border-radius: 10px!important;
  border: 2px solid #fcc505 !important;
  text-align: center;
  color: white !important;
}

.btn-success:hover{
  border: 2px solid black !important;
  color: black !important;
}