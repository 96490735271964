.contenedor {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	background: url("../../assets/images/rnpback2.png") no-repeat center center;
	background-size: 100% 100%; 
	background-position: center;
}