.contenedorcertificado2 {
  background: #e8eaee;
  color: #0295d5 !important;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.contenedorcertificado2 p {
  margin: 7px -7px;
  font-weight: 500;
}

.contenedorcertificado5 {
  /* background: #B3E6FA; */
  background: #e8eaee;
  color: #0295d5 !important;
  border: 3px solid #0295d5;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  transition: all 0.9s;
  margin-top: -20px;
  margin-bottom: 10px;
}

.contenedorcertificado5:hover {
  background: #c8c9cc;
  /*border: 3px solid #0e5472;
  color: white !important;*/
}

.contenedorcertificado5 p {  
  margin-bottom: -3px;
}