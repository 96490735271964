body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

div#root {
	background-color: #FBFBFB;
}

div.App {
	/*background-color: rgb(255, 255, 255);*/
}

.App,
.home,
#root {
	min-height: 100vh;
	height: 100%;
}

@media only screen and (max-width: 360px) {
	.row {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	.col-sm {
		padding-right: 0px !important;
		padding-left: 0px !important;
	}
}
