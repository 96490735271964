.places-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  width: 80%;
  max-width: 800px;
}

.places-scroll {
  overflow-y: scroll; 
  height: 300px;
}

.places-content-button {
  border: 2px solid white; 
  border-radius: 10px;
  margin: 5px 5px;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.9s;
}

.places-content-button div {
  margin: 10px 10px;
}

.places-content-button h4 {
  color: #f69200; 
  font-weight: bold;
}

.places-content-button span {
  color: white; 
  font-size:large;
  font-weight: bold;
}

.places-scroll::-webkit-scrollbar {
  width: 30px;
}

.places-scroll::-webkit-scrollbar-track {
  background: rgb(241, 241, 241,0.5);
  border-radius: 30px;
}

.places-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 30px;
  border: 2px solid #e8eaee;
}

.places-scroll::-webkit-scrollbar-thumb:hover {
  background: black;
}

.places-content-button:hover {
  background-color: rgba(255, 165, 0, 0.2);
  cursor: pointer;
  border: 2px solid black; 
}