.usernameid {
  text-align: center;
  color: #6d757a;
  font-size: 1.5em;
  margin: 1.5em 0 1em 0;
}

.usernameid span {
  color: #f16709;
  font-weight: bold;
  text-align: center;
}